import { MdHome, MdLock, MdPerson } from 'react-icons/md';

// Account Imports
import AccountBilling from 'views/admin/account/billing';
import AccountSettings from 'views/admin/account/settings';

// Auth Imports
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword.jsx';
import SignIn from 'views/auth/signIn/SignIn.jsx';
import SignUp from 'views/auth/signUp/SignUp.jsx';
import ResetPassword from 'views/auth/resetPassword/ResetPassword.jsx';

// Dashboard
import Articles from 'views/admin/dashboard/articles';
import MyArticles from 'views/admin/dashboard/my-articles';
import EditArticle from 'views/admin/dashboard/edit-article';
import ViewArticle from 'views/admin/dashboard/view-article';
import AdminArticles from 'views/admin/dashboard/admin';

const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Articles',
        layout: '/admin',
        path: '/dashboard/articles',
        exact: false,
        component: <Articles />,
      },
      {
        name: 'My Articles',
        layout: '/admin',
        path: '/dashboard/my-articles',
        exact: false,
        component: <MyArticles />,
      },
      {
        name: 'Edit Article',
        layout: '/admin',
        path: '/dashboard/edit-article/:id',
        exact: false,
        hide: true,
        component: <EditArticle />,
      },
      {
        name: 'Admin',
        layout: '/admin',
        path: '/dashboard/admin',
        exact: false,
        hide: false,
        adminView: true,
        component: <AdminArticles />,
      },
    ],
  },
  {
    name: 'Account',
    path: '/account',
    icon: <MdPerson className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Profile Settings',
        layout: '/admin',
        path: '/account/settings',
        exact: false,
        component: <AccountSettings />,
      },
      {
        name: 'Billing Information',
        layout: '/admin',
        path: '/account/billing',
        exact: false,
        component: <AccountBilling />,
      },
    ],
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    hide: true,
    path: '/',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Log In',
        layout: '/auth',
        path: '/:referral?',
        component: <SignIn />,
      },
      {
        name: 'Sign Up',
        layout: '/auth',
        path: '/sign-up',
        component: <SignUp />,
      },
      {
        name: 'Reset Password',
        layout: '/auth',
        path: '/reset-password',
        component: <ResetPassword />,
      },
      {
        name: 'Forgot Password',
        layout: '/auth',
        path: '/forgot-password',
        component: <ForgotPassword />,
      },
      {
        name: 'View Article',
        layout: '/auth',
        path: '/view-article?',
        component: <ViewArticle />,
      },
    ],
  },
];
export default routes;
